// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-page-container-about-template-js": () => import("./../../../src/page-container/about-template.js" /* webpackChunkName: "component---src-page-container-about-template-js" */),
  "component---src-page-container-cosa-facciamo-template-js": () => import("./../../../src/page-container/cosa-facciamo-template.js" /* webpackChunkName: "component---src-page-container-cosa-facciamo-template-js" */),
  "component---src-page-container-support-us-template-js": () => import("./../../../src/page-container/support-us-template.js" /* webpackChunkName: "component---src-page-container-support-us-template-js" */),
  "component---src-page-container-voulunteering-template-js": () => import("./../../../src/page-container/voulunteering-template.js" /* webpackChunkName: "component---src-page-container-voulunteering-template-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-search-js": () => import("./../../../src/pages/search.js" /* webpackChunkName: "component---src-pages-search-js" */),
  "component---src-templates-comitati-locali-archive-js": () => import("./../../../src/templates/comitati-locali-archive.js" /* webpackChunkName: "component---src-templates-comitati-locali-archive-js" */),
  "component---src-templates-contatti-js": () => import("./../../../src/templates/contatti.js" /* webpackChunkName: "component---src-templates-contatti-js" */),
  "component---src-templates-faq-js": () => import("./../../../src/templates/faq.js" /* webpackChunkName: "component---src-templates-faq-js" */),
  "component---src-templates-local-news-js": () => import("./../../../src/templates/local-news.js" /* webpackChunkName: "component---src-templates-local-news-js" */),
  "component---src-templates-local-news-region-js": () => import("./../../../src/templates/local-news-region.js" /* webpackChunkName: "component---src-templates-local-news-region-js" */),
  "component---src-templates-news-archive-js": () => import("./../../../src/templates/news-archive.js" /* webpackChunkName: "component---src-templates-news-archive-js" */),
  "component---src-templates-publication-archive-js": () => import("./../../../src/templates/publication-archive.js" /* webpackChunkName: "component---src-templates-publication-archive-js" */),
  "component---src-templates-sala-stampa-js": () => import("./../../../src/templates/sala-stampa.js" /* webpackChunkName: "component---src-templates-sala-stampa-js" */),
  "component---src-templates-single-post-template-js": () => import("./../../../src/templates/single-post-template.js" /* webpackChunkName: "component---src-templates-single-post-template-js" */),
  "component---src-templates-single-province-js": () => import("./../../../src/templates/single-province.js" /* webpackChunkName: "component---src-templates-single-province-js" */),
  "component---src-templates-single-publication-js": () => import("./../../../src/templates/single-publication.js" /* webpackChunkName: "component---src-templates-single-publication-js" */),
  "component---src-templates-single-regioni-js": () => import("./../../../src/templates/single-regioni.js" /* webpackChunkName: "component---src-templates-single-regioni-js" */),
  "component---src-templates-stories-js": () => import("./../../../src/templates/stories.js" /* webpackChunkName: "component---src-templates-stories-js" */)
}

